<template>
  <div class="next_production">
    <div class="plan_tag">
      <p class="plan_tag_l">
        <span class="tag_01" @click="() => {goPage('/cases')}">
          {{$t('casesDetail.casesChildren.common.bl')}}
        </span> &gt;
        <span class="tag_02" @click="() => {goPage('/cases/detail')}">
          {{$t('casesDetail.casesChildren.common.blxq')}}
        </span> &gt;{{$t('casesDetail.casesChildren.nextProduction.xypsc')}}
      </p>
      <p class="plan_tag_r" @click="goBack">&lt;&lt; {{$t('casesDetail.casesChildren.nextProduction.fhsyy')}}</p>
    </div>
    <div class="plan_head">
      <span class="plan_h_strong">{{$t('casesDetail.casesChildren.nextProduction.blh')}}：{{casesInfo.caseNumber}}</span>
      <span class="plan_h_sp">{{casesInfo.clinicName}}</span>
      <span class="plan_h_sp">{{casesInfo.productName}}</span>
    </div>
    <div class="next_box">
      <div class="next_con">
        <div class="next_c_top">
          <div class="next_u_pic" :style="{backgroundImage: `url('${casesInfo.photo ? $PicPrefix + casesInfo.photo : defPhoto}')`}">
          </div>
          <div class="next_u_box">
            <h5 class="next_u_name">
              {{casesInfo.realName}}
              <span
                class="xb_icon"
                :style="{backgroundImage: `url('${casesInfo.sex === '1' ? BOY_ICON : GIRL_ICON}')`}"
              ></span>
            </h5>
            <p class="next_u_b_p">
              <span class="r_sr_icon"></span>
              {{casesInfo.age}}（{{casesInfo.birthdayStr}}）
            </p>
            <p class="next_u_b_p">
              <span class="r_m_icon" style="margin-left: 0;"></span>
              {{casesInfo.mobile}}
            </p>
            <p class="next_u_b_p">
              <span class="r_d_icon"></span>
              {{casesInfo.address}}
            </p>
          </div>
        </div>
        <div class="next_c_foo">
          <div class="main_big_btn main_theme_color_btn_white" @click="toReadjust">{{$t('casesDetail.casesChildren.nextProduction.cxtz')}}</div>
          <div class="main_big_btn main_theme_color_btn" @click="submitFn">{{$t('casesDetail.casesChildren.nextProduction.wxtz')}}</div>
        </div>
      </div>
    </div>
    <confirm-modal
      :confirmObj="{
        tipText: $t('casesDetail.casesChildren.common.qrjx'),
        backText: $t('casesDetail.casesChildren.common.qx'),
        okText: $t('casesDetail.casesChildren.common.qd')
      }"
      @confirmSubmit="confirmSubmit"
      @confirmHide="confirmHide"
      v-show="isShow"
    />
    <FullLoading v-show="isLoading"/>
  </div>
</template>

<script>
  import {deliverAgain, curePlanReadjust} from 'common/api/cases';
  import {defPhoto, BOY_ICON, GIRL_ICON} from 'common/js/requireImage';
  import {notifyMsg} from 'common/js/util';
  import FullLoading from 'components/full-loading/full-loading';
  import ConfirmModal from 'components/confirm-modal/confirm';
  export default {
    data() {
      return {
        defPhoto,
        BOY_ICON,
        GIRL_ICON,
        isShow: false,
        isLoading: false,
        casesInfo: {},
        type: '0' // 0 重新调整  1 无需调整
      }
    },
    created() {
      document.title = this.$t('casesDetail.casesChildren.nextProduction.fhsyy');
      const casesDetail = localStorage.getItem('casesDetail') || sessionStorage.getItem('casesDetail') || '';
      sessionStorage.setItem('casesDetail', casesDetail);
      localStorage.removeItem('casesDetail');
      const casesId = localStorage.getItem('casesId') || sessionStorage.getItem('casesId') || '';
      const curePlanId = localStorage.getItem('curePlanId') || sessionStorage.getItem('curePlanId') || '';
      localStorage.removeItem('casesId');
      localStorage.removeItem('curePlanId');
      sessionStorage.setItem('casesId', casesId);
      sessionStorage.setItem('curePlanId', curePlanId);
      if (casesDetail) {
        const data = JSON.parse(casesDetail);
        const {productName, clinicId, clinicName, caseNumber} = data;
        const {realName, photo, address = {}, age, birthdayStr, mobile, sex} = data.userInfo;
        const {provinceName = '', cityName = '', areaName = ''} = address;
        this.casesInfo = {
          caseNumber,
          clinicName,
          clinicId,
          productName,
          realName,
          photo,
          address: `${provinceName}-${cityName}-${areaName}-${address.address || ''}`,
          age,
          sex,
          birthdayStr,
          mobile
        };
      }
    },
    methods: {
      submitFn() {
        this.type = '1';
        this.isShow = true;
      },
      toReadjust() {
        this.type = '0';
        this.isShow = true;
      },
      confirmSubmit() {
        const curePlanId = localStorage.getItem('curePlanId') || sessionStorage.getItem('curePlanId') || '';
        sessionStorage.setItem('curePlanId', curePlanId);
        localStorage.removeItem('curePlanId');
        if (!curePlanId) {
          this.isShow = false;
          return notifyMsg(this, 'warning', this.$t('casesDetail.casesChildren.common.czsb'));
        }
        this.isLoading = true;
        const opApi = this.type === '0' ? curePlanReadjust : deliverAgain;
        opApi({id: curePlanId}).then(() => {
          this.isLoading = false;
          this.isShow = false;
          notifyMsg(this, 'success', this.$t('casesDetail.casesChildren.common.czcg')).then(() => {
            this.goBack();
          });
        }).catch(() => {
          this.isLoading = false;
          this.isShow = false;
        });
      },
      confirmHide() {
        this.isShow = false;
      },
      goBack() {
        this.$router.push('/cases/detail');
      },
      goPage(url) {
        this.$router.push(url);
      }
    },
    components: {
      FullLoading,
      ConfirmModal
    }
  }
</script>

<style scoped lang="scss">
  .next_production{
    width: 13.44rem;
    margin: 0 auto;
    color: $main_theme_color_333;
    font-size: 0.16rem;
    span{
      display: inline-block;
    }
    .plan_tag{
      padding: 0.2rem 0;
      color: $main_theme_color_333;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .plan_tag_l{
        display: flex;
        align-items: center;
        .tag_01{
          margin-right: 0.04rem;
          cursor: pointer;
        }
        .tag_02{
          margin: 0 0.04rem;
          cursor: pointer;
        }
      }
      .plan_tag_r{
        color: $main_theme_color;
        cursor: pointer;
      }
    }
    .plan_head{
      display: flex;
      align-items: center;
      margin-bottom: 0.2rem;
      .plan_h_strong{
        font-size: 0.22rem;
        font-weight: bold;
      }
      .plan_h_sp{
        padding-left: 0.1rem;
        border-left: 0.01rem solid #B5B5B5;
        margin-left: 0.1rem;
        font-size: 0.18rem;
      }
    }
    .next_box{
      background-color: #fff;
      border-radius: 0.1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.6rem 0 0.75rem;
      color: $main_theme_color_333;
      .next_con{
        width: 40%;
        .next_c_top{
          margin-bottom: 0.6rem;
          display: flex;
          align-items: center;
          .next_u_pic{
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 0.3rem;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }
          .next_u_name{
            display: flex;
            align-items: center;
            font-size: 0.22rem;
            margin-bottom: 0.22rem;
            .xb_icon{
              margin-left: 0.08rem;
              width: 0.18rem;
              height: 0.18rem;
              background-size: 100% 100%;
            }
          }
          .next_u_b_p{
            display: flex;
            align-items: center;
            margin-bottom: 0.2rem;
            color: #666;
            &:last-child {
              margin-bottom: 0;
            }
            span{
              background-size: 100% 100%;
              margin-right: 0.06rem;
            }
          }
        }
        .next_c_foo{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0.23rem;
          span{
            width: 1.78rem;
            padding: 0.12rem 0;
            text-align: center;
            cursor: pointer;
            border-radius: 1rem;
          }
          .foo_back{
            margin-right: 0.6rem;
          }
        }
      }
    }
  }
</style>
